const state = {
  toasts: [],
  id: 0
}

const mutations = {
  ADD(state, toast) {
    toast.id = state.id++
    state.toasts.push(toast)
  },

  REMOVE(state, id) {
    const i = state.toasts.findIndex(t => t.id === id)
    state.toasts.splice(i, 1)
  }
}

const actions = {
  add({ commit }, toast) {
    commit('ADD', toast)
  },

  remove({ commit }, id) {
    commit('REMOVE', id)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
