const state = {
  show: 'responsive',
  minimize: false
}

const mutations = {
  SET(state, [key, value]) {
    state[key] = value
  }
}

const actions = {
  toggleDesktop({ state, commit }) {
    const sidebarOpened = [true, 'responsive'].includes(state.show)
    commit('SET', ['show', sidebarOpened ? false : 'responsive'])
  },

  toggleMobile({ state, commit }) {
    const sidebarClosed = [false, 'responsive'].includes(state.show)
    commit('SET', ['show', sidebarClosed ? true : 'responsive'])
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
