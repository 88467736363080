<template>
  <c-toaster class="notifications" :autohide="3000" position="bottom-right">
    <template v-for="toast in $store.state.notify.toasts">
      <c-toast
        :key="toast.id"
        :show="true"
        :class="`toast-${toast.type}`"
        @update:show="$store.dispatch('notify/remove', toast.id)"
      >
        <c-icon
          v-if="$options.icons[toast.type]"
          class="mr-2"
          :name="$options.icons[toast.type]"
        />
        {{ toast.text }}
      </c-toast>
    </template>
  </c-toaster>
</template>

<script>
export default {
  icons: {
    success: 'cil-check-alt',
    error: 'cil-warning'
  }
}
</script>

<style lang="scss">
.notifications .toast {
  border-left: 4px solid var(--info);

  &-success {
    border-left-color: var(--success);
    .c-icon {
      color: var(--success);
    }
  }

  &-error {
    border-left-color: var(--danger);
    .c-icon {
      color: var(--danger);
    }
  }
}
</style>
