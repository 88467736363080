import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import store from './store'

const AppContainer = () => import('@/components/app/AppContainer')
const RouterView = { render: c => c('router-view') }

const routes = [
  {
    path: '/',
    component: AppContainer,
    meta: { auth: true },
    children: [
      {
        path: '',
        redirect: 'orgs'
        // name: 'Dashboard',
        // component: () => import('@/views/Dashboard')
      },
      {
        path: 'orgs',
        component: RouterView,
        redirect: 'orgs',
        name: 'Organizations',
        children: [
          {
            path: '',
            component: () => import('@/views/OrgList')
          },
          {
            path: ':org',
            name: 'Details',
            component: () => import('@/views/OrgAnalytics'),
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('@/views/Login')
      }
    ]
  }
]

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.auth)
  if (requireAuth && !store.state.user.id) {
    next({ name: 'Login', query: { goto: to.fullPath } })
  } else {
    next()
  }
})

export default router
