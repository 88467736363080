import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { icons } from './assets/icons/icons.js'
import store from './store'

Vue.config.performance = true
Vue.use(CoreuiVue)

Vue.prototype.$log = console.log.bind(console)

Vue.prototype.$copy = navigator.clipboard.writeText.bind(navigator.clipboard)

Vue.prototype.$notify = (text, type) => {
  store.dispatch('notify/add', { type, text })
}

const init = () => {
  new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
      App
    }
  })
}

store
  .dispatch('user/refresh')
  .then(init)
  .catch(init)
