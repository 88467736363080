import store from '@/store'

function fetchMethod(method = 'GET') {
  return (url, value, config = {}) => {
    const defaults = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    }

    let token = store.state.user.token.value
    if (token) {
      defaults.headers['Authorization'] = `Bearer ${token}`
    }

    config = Object.assign(defaults, { method }, config)

    if (value) {
      if (typeof value === 'object') {
        if (value.source === undefined) {
          // show only domain and page (no protocol or query params)
          const { host, pathname } = window.location
          value.source = `web:${host + pathname}`
        }
        value = JSON.stringify(value)
      }
      config.body = value
    }

    return fetch(url, config)
      .then(async res => {
        const data = await res.json()
        if (!res.ok) {
          throw data
        }
        return data
      })
      .catch(error => {
        let msg = 'An error occurred with the request'
        if (error.error) {
          msg = error.error.message
        }
        store.dispatch('notify/add', { type: 'error', text: msg })
        return {}
      })
  }
}

export const get = fetchMethod()

export const post = fetchMethod('POST')
