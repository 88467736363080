<template>
  <div v-if="$store.state.loading" class="app-loading">
    <div class="atom-spinner">
      <div class="spinner-inner">
        <div class="spinner-line"></div>
        <div class="spinner-line"></div>
        <div class="spinner-line"></div>
        <!--Chrome renders little circles malformed :(-->
        <div class="spinner-circle">&#9679;</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
// Spinner from https://epic-spinners.epicmax.co/#/
.app-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.atom-spinner {
  height: 1em;
  width: 1em;
  margin-left: auto;
  margin-right: auto;
  font-size: 100px;
  color: var(--primary);
  overflow: hidden;
  background: #fff;
  padding: 10px;
  border-radius: 50%;

  .spinner-inner {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
  }

  .spinner-circle {
    display: block;
    position: absolute;
    font-size: 0.2em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner-line {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    border-left: 0.03em solid;
    border-top: 0.03em solid transparent;

    &:nth-child(1) {
      animation-name: orbit-1;
      transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
    }

    &:nth-child(2) {
      animation-name: orbit-2;
      transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
    }

    &:nth-child(3) {
      animation-name: orbit-3;
      transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
    }
  }
}

@keyframes orbit-1 {
  100% {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}
@keyframes orbit-2 {
  100% {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}
@keyframes orbit-3 {
  100% {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}
</style>
