import { get, post } from './utils'
import store from '@/store'

const ROOT = process.env.VUE_APP_API

const ROUTES = {
  ADMIN: ROOT + '/admin',
  USER: ROOT + '/user'
}

export default {
  ...ROUTES,

  getOrganizationList() {
    return get(`${ROUTES.ADMIN}/dashboard/list`)
  },

  getOrganizationInfo(name) {
    const params = new URLSearchParams({ name })
    return get(`${ROUTES.ADMIN}/dashboard/info?${params}`)
  },

  getOrganizationData(name, min_date) {
    const params = new URLSearchParams({ name, min_date })
    return get(`${ROUTES.ADMIN}/dashboard/data?${params}`)
  },

  login({ login, pass }) {
    return post(`${ROUTES.USER}/login`, { login, pass })
  },

  logout() {
    const token = store.state.user.token.value
    return post(`${ROUTES.USER}/logout`, { token })
  },

  refresh() {
    const token = store.state.user.token.value
    if (!token) return {}
    return get(`${ROUTES.USER}/session`)
  }
}
