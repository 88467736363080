import API from '@/api'

const state = {
  id: null,
  name: null,
  email: null,
  token: JSON.parse(localStorage.getItem('token')) || {}
}

const mutations = {
  PATCH(state, value) {
    Object.assign(state, value)

    if (value.token) {
      localStorage.setItem('token', JSON.stringify(value.token))
    }
  }
}

const actions = {
  async login({ dispatch }, { login, pass }) {
    const res = await API.login({ login, pass })
    dispatch('updateUser', res)
    return res.success
  },

  async logout() {
    await API.logout()
    localStorage.removeItem('token')
    location.reload()
  },

  async refresh({ dispatch }) {
    const res = await API.refresh()
    dispatch('updateUser', res)
    return res.success
  },

  updateUser({ commit, dispatch }, { success, results }) {
    if (!success) {
      localStorage.removeItem('token')
      return
    }

    if (results.permissions && results.permissions.includes('dash.access')) {
      commit('PATCH', { ...results.user, token: results.token })
    } else {
      dispatch(
        'notify/add',
        { type: 'error', text: 'You do not have permission' },
        { root: true }
      )
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
