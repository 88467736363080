import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import data from './modules/data'
import notify from './modules/notify'
import sidebar from './modules/sidebar'
import user from './modules/user'

const state = {
  loading: 0
}

const getters = {
  loading: state => !!state.loading
}

const mutations = {
  LOAD_INCREMENT: state => state.loading++,
  LOAD_DECREMENT: state => state.loading--
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  modules: {
    data,
    notify,
    sidebar,
    user
  }
})
