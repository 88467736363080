<template>
  <div>
    <router-view />
    <app-notifications />
    <app-loading />
  </div>
</template>

<script>
import AppLoading from '@/components/app/AppLoading'
import AppNotifications from '@/components/app/AppNotifications'

export default {
  components: {
    AppLoading,
    AppNotifications
  },

  name: 'App',

  mounted() {
    document.body.addEventListener('click', e => {
      document.body.classList.add('using-mouse')
      if (e.target.tagName === 'BUTTON') e.target.blur()
    })

    document.body.addEventListener('keydown', e => {
      if (e.keyCode === 9) {
        document.body.classList.remove('using-mouse')
      }
    })
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';

body.using-mouse :focus {
  box-shadow: none !important;
  outline: none !important;
}
</style>
