import API from '@/api'

const state = {
  companies: [],
  lastDays: 7
}

const mutations = {
  SET(state, [key, value]) {
    state[key] = value
  }
}

const actions = {
  async getCompanies({ commit, state }) {
    if (state.companies.length) return

    commit('LOAD_INCREMENT', null, { root: true })
    const { success, results } = await API.getOrganizationList()
    commit('LOAD_DECREMENT', null, { root: true })

    if (success) {
      const orgs = results.map(o => {
        o.licenses_purchased = +o.licenses_purchased
        return o
      })
      commit('SET', ['companies', orgs])
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
