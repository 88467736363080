import {
  cilBuilding,
  cilCheckAlt,
  cilLockLocked,
  cilSpeedometer,
  cilUser,
  cilWarning
} from '@coreui/icons'
import { logo } from './logo'

export const icons = Object.assign(
  {},
  { logo },
  {
    cilBuilding,
    cilCheckAlt,
    cilLockLocked,
    cilSpeedometer,
    cilUser,
    cilWarning
  }
)
